.closeButton{
    border: none;
    background-color: white;
    font-weight: bolder;
    font-size: 20px;
}
.modalBody{
    margin: 5vh 0vh 5vh 0vh;
    display: flex;
    flex-direction: column;
    gap: 2vh;
}
.quotationcard{
    box-shadow: 1px 0px 10px 0px grey;
    border-radius: 10px;
    height: 15vh;
    margin: 2vh;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.approveDiv{
    /* background-color: green; */
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}
.rejectDiv{
    /* background-color: red; */
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}
.editDiv{
    background-color: red;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

/* HIDE RADIO */
[type=radio] { 
    position: absolute;
    opacity: 0;
    /* width: 0;
    height: 0; */
    
  }

  .rejectDiv input[type="radio"]:checked + span{
    background-color: #f4bdbd;
    /* display: inline-block; */
    padding: 0px 6px;
  }
  .approveDiv input[type="radio"]:checked + span {
    background-color: #90cca7;
    padding: 0px 6px;
    /* display: inline-block; */
  }

  /* IMAGE STYLES */
[type=radio] + span {
    cursor: pointer;
    /* display: inline-block; */
  }

/* .radio-toolbar {
    margin: 10px;
  }
  
  .radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  
  .radio-toolbar label {
      display: inline-block;
      background-color: #ddd;
      padding: 10px 20px;
      font-family: sans-serif, Arial;
      font-size: 16px;
      border: 2px solid #444;
      border-radius: 4px;
  }
  
  .radio-toolbar label:hover {
    background-color: #dfd;
  }
  
  .radio-toolbar input[type="radio"]:focus + label {
      border: 2px dashed #444;
  }
  
  .radio-toolbar input[type="radio"]:checked + label {
      background-color: #bfb;
      border-color: #4c4;
  } */
