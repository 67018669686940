.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
p {
  font-size: 12px;
  margin-bottom: 0px;
}
label{
  font-size: 12px;
}
.css-79ws1d-MuiModal-root{
  overflow-y: auto !important;
}
/* .css-h4y409-MuiList-root{
  padding-top: 0px !important;
}

.css-13cymwt-control{
  max-height: 60px !important;
  overflow: auto !important;
} */

ul{
  padding-left: 0px !important;
  padding: 0px !important;
}
input[type=file]{
  font-size: 12px ;
  color: rgb(134, 134, 134) !important;
}
.select__input{
  min-width: 150px !important;
}

/* *********ANTD Date Picker*************** */
.ant-picker-dropdown{
  z-index: 9999 !important;
}
.ant-picker-input >input{
  font-size: 12px !important;
}
.ant-picker-panel-layout{
  max-height: 49vh !important;
}

/* *********SIDEBAR*************** */
.css-10hburv-MuiTypography-root{
  font-size: 16px !important;
}

.css-13cymwt-control {   
  background-color: inherit !important;
}
/* ****************Scroll**************** */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(143, 138, 138, 0.3);
  background-color: #eee;
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #2a3140;
}
::-webkit-scrollbar-thumb {
  background-color: #2a3140;
}

/***************** Table**************** */
.css-pdct74-MuiTablePagination-selectLabel{
  margin-bottom: 0px !important;
}
.tss-1cdcmys-MUIDataTable-responsiveBase table thead tr th{
  padding: 5px!important;
  color: white;
  background-color: #007cc3 !important;
  text-align: center;
  font-size: 14px !important;
} 
.tss-1cdcmys-MUIDataTable-responsiveBase table tbody tr td{
  padding: 0px!important;
} 
.css-rqglhn-MuiTable-root, .css-lp9mh7 tfoot tr td{
  padding: 0px!important;
} 
table>tfoot>tr>td>div>div>div{
  display: flex !important;
  align-items: baseline !important;
  font-size: 12px !important;
}
h4{
  font-size: 18px;
}

/* ****************Table center align**************** */
.tss-1qtl85h-MUIDataTableBodyCell-root{
  display: flex;
  justify-content: center;
  font-size: 12px ;
}
.tss-178gktx-MUIDataTableHeadCell-contentWrapper{
  display: flex;
  justify-content: center;
}

#categoryTable .css-1w1rijm-MuiButtonBase-root-MuiButton-root,
.tss-1akey0g-MUIDataTableHeadCell-data{
  text-transform: none;
}
.css-hyum1k-MuiToolbar-root, .css-i6s8oy{ 
  min-height: 45px !important
}
.css-nfmglb-MuiTableCell-root, .css-8nphli{ 
  min-height: 40px !important;
  padding: 0px !important;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
  min-height: 45px !important;
  font-size: 12px !important;
}
.css-pdct74-MuiTablePagination-selectLabel{
  font-size: 12px !important;
}
.css-levciy-MuiTablePagination-displayedRows{
  font-size: 12px !important;
}
.tableName{
  font-size: 18px;
}
.addButton{
  font-size: 12px;
}
.fs-14{
  font-size: 12px;
}
::placeholder {
  font-size: 12px;
  color: rgb(134, 134, 134) !important;
}
#addSubmitButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35vh;
  font-size: 12px;
  margin-top: 2vh;
}
.editButton{
  color: #007cc3;
  cursor: pointer;
  font-size: 18px !important;
}
.deleteButton{
  color: red;
  cursor: pointer;
  font-size: 18px !important;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* ****************Sweet alert**************** */
body.swal2-toast-shown 
.swal2-container.swal2-top-end,
 body.swal2-toast-shown .swal2-container.swal2-top-right {
   top: auto;
   right: 0;
   bottom: 0;
   left: auto;
   z-index: 99999 !important;
  }
  body.swal2-toast-shown .swal2-container{
    width: auto !important;
  }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .tss-tlx3x1-MUIDataTableToolbar-root {
  background-color: #007cc3;
  color: white;
  border-bottom: 1px solid white;
} */

.tss-1937jnc-MUIDataTableHead-main{
  background-color: #007cc3 !important;
  color: white !important;
}

/* .css-i4bv87-MuiSvgIcon-root,
.css-slyssw,
.css-1yxmbwk {
  color: white !important;
  background-color: #89BBE0 !important;
  color: #444a4f !important;
  color: white !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ****************radio button**************** */
.square-radio {
  border: 1px solid black;
  margin: 2px;
  width: 40px;
  height: 40px;
  position: relative;

}
.square-radio--clicked .square-radio--content{
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: black;
  width: 20px;
  height: 20px;
}


.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 9999 !important;
}


/* ****************Firefox**************** */
input[type=number] {
  -moz-appearance: textfield;
}
input[type=email] {
  background-image:none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
