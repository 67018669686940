#totalCard {
  margin-top: 4vh;
  border: 1px solid #e5dada;
  border-radius: 12px;
  /* box-shadow: -2px 2px 5px 0px; */
  /* width: 40%; */
}
#bckbtn {
  cursor: pointer;
  border: 1px solid #007ec0;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  color: #007ec0;
  background-color: transparent;
}

#bckbtn:hover {
  -webkit-animation: bounceLeft 2s infinite;
  animation: bounceLeft 2s infinite;
}
.quotationcard{
  box-shadow: 1px 0px 10px 0px grey;
  border-radius: 10px;
  height: 18vh;
  margin: 2vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  overflow: auto;
}
.forRejected{
  box-shadow: 1px 0px 10px 0px grey;
  border-radius: 10px;
  height: 18vh;
  margin: 2vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  overflow: auto;
}
.forRejected:hover {
  /* opacity: 0.3; */
  filter: blur(3px);
}
.forRejected:hover + .rejectedButtons {
  display: flex;
  justify-content: space-between;
  visibility: visible;
  position: relative;
  bottom: 78px;
  margin-left: 8vh;
  padding-left: 10px;
  width: 60%;
}
.selectEdit:hover{
  display: flex;
  justify-content: space-between;
  visibility: visible;
  position: relative;
  bottom: 78px;
  margin-left: 8vh;
  padding-left: 10px;
  width: 60%;
  
}
.rejectedButtons{
  display: none;
}

.approved {
  border: #92d087;
  background-color: #92d087;
  border-radius: 5px;
  height: 4vh;
  width: 4vh;
  display: block;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.rejected {
  border: #ed4545;
  background-color: #ed4545;
  border-radius: 5px;
  height: 4vh;
  width: 4vh;
  display: block;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
/* .rejected {
  border: #f86a6a;
  background-color: #f86a6a;
  border-radius: 5px;
  height: 4vh;
  width: 4vh;
  display: block;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
  color: white;
} */
.inprogress {
  border: black 1px solid;
  background-color: white;
  border-radius: 5px;
  height: 4vh;
  width: 4vh;
  display: block;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
}
.partiallyRejected {
  border: white 1px solid;
  color: white;
  background-color: #FF7F3E;
  border-radius: 5px;
  height: 4vh;
  width: 4vh;
  display: block;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
}
.poRejected {
  border: white 1px solid;
  color: white;
  background-color: #ed4545;
  border-radius: 5px;
  height: 4vh;
  width: 4vh;
  display: block;
  padding: 2px;
  font-size: 14px;
  font-weight: bold;
}
.readMoreClassName,
.readLessClassName {
  color: #007cc3;
}
@media only screen and (width: 1280px) {
  /* width: ; */
}
