#footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: grey;
  text-align: center;
  z-index: 1;
  background-color: white;
  height: 4.5%
}

#backgroundImage {
  margin-top: -4em;
  margin-left: -4em;
}
#image {
  max-height: 100vh;
  width: 100vw;
  object-fit: cover;
}

#loginPageDiv {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  padding: 20vh 10vh 5vh 10vh;
}

#loginCard {
  height: 65vh;
  width: 65vh;
  background: #ffffffcf !important;
  text-align: center;
  border-radius: 10px;
  padding: 3vw;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.purchaseName {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 5vh;
}

/* Venus login css */
#main_contain {
  background-color: white;
  height: 100%;
}
#logo {
  /* width: 50%;
    margin-top: 20px;
    margin-bottom: 20px; */
  /* cursor: pointer; */
  width: 10%;
  display: flex;
  /* padding-top: 1%; */
  margin-left: 1%;
  margin-top: 1.5%;
  /* border-left-style: solid;
    border-left-color: grey;
    border-left-width: 5px; */
}
.logoSepeartor {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 95px;
  margin-top: -25px;
}
.logoContainer {
  display: flex;
  justify-content: center;
}
#left_part > img {
  height: 100vh;
  width: auto;
  object-fit: cover;
  /* margin-top: 80px;
    margin-left: -10%; */
}
#loginImage {
  height: 80vh;
  width: 48vw;
  /* margin-top: 20px; */
  border-radius: 25px;
  opacity: 0.8;
}

#right_part {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* #right_part {
    height: 460px;
    width: 550px;
    margin-top: 8%;
    margin-left: -5%;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    border-radius: 15px;
  }
  #right_part > h2 {
    font-size: 35px;
    font-weight: 500;
    margin-top: 10px;
  }
  #right_part > p {
    color: grey;
    margin-top: -10px;
  } */
.logoSepeartor {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 95px;
  margin-top: -25px;
}
.logoContainer {
  display: flex;
  justify-content: center;
}
#login_btn {
  background-color: #007cc3;
  color: white;
  font-weight: bold;
  /* width: 80%; */
  margin-top: 40px;
  text-transform: capitalize;
}

@media all and (max-width: 759px) and (min-width: 50px) {
  #left_part > img {
    height: auto;
    width: auto;
    object-fit: cover;
    margin-top: 80px;
    margin-left: -10%;
  }
}
